import mbxDatasets from '@mapbox/mapbox-sdk/services/datasets';
import mapboxgl from 'mapbox-gl';
import { throttle } from 'underscore';

export default function createMap(mbxToken, mbxStyle, mbxDataset, container, center, navPosition) {
  let boundingbox;

  mapboxgl.accessToken = mbxToken;
  const map = new mapboxgl.Map({
    container,
    style: mbxStyle,
    zoom: 5,
    center,
    interactive: true,
    scrollZoom: false,
  });

  const datasetsService = mbxDatasets({ accessToken: mbxToken });
  datasetsService
    .listFeatures({
      datasetId: mbxDataset,
    })
    .send()
    .then(response => {
      response.body.features.forEach(feature => {
        if (feature.properties.type === 'boundingbox') {
          boundingbox = [feature.geometry.coordinates[0][0], feature.geometry.coordinates[0][2]];
        }
      });
    })
    .then(() => {
      map.fitBounds(boundingbox);

      window.addEventListener(
        'resize',
        throttle(() => {
          map.fitBounds(boundingbox);
        }, 10)
      );
    });

  const nav = new mapboxgl.NavigationControl();
  map.addControl(nav, navPosition);
}
